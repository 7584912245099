import { Box, ImageList, ImageListItem, useMediaQuery } from '@mui/material';
import React from 'react';

function Frank() {
	const itemData = [
		{
			img: './images/frank-florida.jpg',
            title: 'Frank on beach in Florida'
		},
		{
			img: './images/first-day-of-school.jpg',
		},
		{
			img: './images/frank-cow.jpg'
		},
		{
			img: './images/frank-plane.jpg'
		},
		{
			img: './images/frank-rock.jpg'
		},
		{
			img: './images/frank-police.jpg'
		},
		{
			img: './images/frank-vik-beach.jpg'
		},
		{
			img: './images/frank-bday-baba.jpg'
		},
		{
			img: './images/bus-2.jpg'
		},
		{
			img: './images/cool-guy.jpg'
		},
		{
			img: './images/sprinkler.jpg'
		},
		{
			img: './images/franklin-safari.jpg'
		},
		{
			img: './images/frank-susan.jpg'
		},
		{
			img: './images/campfire.jpg'
		},
	];

	const matches = useMediaQuery('(min-width:600px)');
	const totalWidth = matches ? 900 : 400;

	return <div className='gimme-my-space-man'>
		<h2 className='curly yuuge'>Frankie</h2>
		<ul>
			<li>Taking the bus to pre-school!</li>
			<li>Turned 4 in May and </li>
			<li>Obsessed with tools.</li>
            <li>Embarking on a superhero phase!</li>
		</ul>
		<Box className='center-box' sx={{ width: totalWidth }}>
			<ImageList variant="masonry" cols={matches ? 2 : 1} gap={12}>
				{itemData.map((item) => (
					<ImageListItem key={item.img}>
						<img
							className='single-img'
							src={`${item.img}?w=248&fit=crop&auto=format`}
							srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
							alt={item.title}
							loading="lazy" />
					</ImageListItem>
				))}
			</ImageList>
		</Box>
	</div>

	
}

export default Frank