import './App.css';
import Frank from './Pages/frank';
import John from './Pages/john';
import Olga from './Pages/olga';
import Sammy from './Pages/sammy';
import Welcome from './Pages/welcome';
import Year2022 from './Pages/Year2022';


function App() {
	return (
		<div className="App">
			<Welcome />
			<Year2022 />
			<Sammy />
			<Frank />
			<Olga />
			<John />
			<p>Copyright ©2022 by Papa is a Web Developer Productions.</p>
		</div>
	);
}

export default App;
