import { Rating } from '@mui/material';
import React from 'react';

function Year2022() {
	const value = 5;

	return (
		<div className="2022">
			<h1 className='curly pretty-darn-big'>2022, Wrapped!</h1>
			<p><i>The year we left the house!</i></p>
			<p>Wefler Rating:</p>
      		<Rating name="read-only" value={value} readOnly />

			<p>'Twas a year of growth, and not just because a Joe's Donut shop opened 3 blocks away.</p>
			<p>'Twas a year of travel, because we finally could!</p>
			<p>'Twas a year of raising children, since that's what we're here for.</p>
		</div>
	)

}

export default Year2022;