import { Box, ImageList, ImageListItem, useMediaQuery } from '@mui/material';
import React from 'react';

function Olga() {
	const itemData = [
		{
			img: './images/mama-birthday.jpg',
            title: `Mama at Frank's Birthday Party`
		},
		{
			img: './images/mama-drinks.jpg',
		},
		{
			img: './images/mom-sam-fla.jpg',
		},
		{
			img: './images/mom-sam-plane.jpg',
		},
		{
			img: './images/olga-sam-ulcinj.jpg',
		},
		{
			img: './images/mom-sam-2.jpg',
		},
		{
			img: './images/mom-wedding-2.jpg',
		},
		{
			img: './images/sam-mom-lighthouse.jpg',
		},
		
	];

	const matches = useMediaQuery('(min-width:600px)');
	const totalWidth = matches ? 900 : 400;

	return <div className='gimme-my-space-man'>
		<h2 className='curly yuuge'>Olya</h2>
		<ul>
			<li>Writing a movie blog: <a href="https://goodmoviefinder.com/">https://goodmoviefinder.com</a>!</li>
			<li>Got to visit with family in Europe in the summer.</li>
            <li>Needs some wine!</li>
		</ul>
		<Box className='center-box' sx={{ width: totalWidth }}>
			<ImageList variant="masonry" cols={matches ? 2 : 1} gap={12}>
				{itemData.map((item) => (
					<ImageListItem key={item.img}>
						<img
							className='single-img'
							src={`${item.img}?w=248&fit=crop&auto=format`}
							srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
							alt={item.title}
							loading="lazy" />
					</ImageListItem>
				))}
			</ImageList>
		</Box>
	</div>

	
}

export default Olga