import React from 'react';

function Welcome() {
    return (
        <div className='page-welcome gimme-my-space-man'>
            <img className="tree-one" src="./images/christmas-tree-1.svg" alt="Tree"></img>
            <img className="tree-two" src="./images/christmas-tree-2.svg" alt="Tree"></img>
            <h1 className='curly yuuge'>Season's Greetings</h1>
            <h3 className="fancy pretty-darn-big ">from the Wefler Family</h3>
			{/* <img src="./image" */}
			<p><i>From our home, to your home, may this season of enchantment bring you great peace and joy.</i></p>
        </div>
    )
}

export default Welcome