import { Box, ImageList, ImageListItem, useMediaQuery } from '@mui/material';
import React from 'react';

function Sammy() {
	const itemData = [
		{
			img: './images/sam-moose.jpg',
			title: 'Sammy the Moose',
		},
		{
			img: './images/sammy-one-balloon.jpg',
		},
		{
			img: './images/sam-papa-james-taylor.jpg'
		},
		{
			img: './images/sam-beach-florida.jpg'
		},
		{
			img: './images/sam-bentley.jpg'
		},
		{
			img: './images/papa-sam-florida.jpg'
		},
		{
			img: './images/sam-table.jpg'
		},
		{
			img: './images/sam-tent.jpg'
		},
		{
			img: './images/sammy-food.jpg'
		},
		{
			img: './images/sam-bubbles-2.jpg'
		},
		{
			img: './images/sammy-eats.jpg'
		},
		{
			img: './images/pumpkins.jpg'
		},
	];

	const matches = useMediaQuery('(min-width:600px)');
	const totalWidth = matches ? 900 : 400;

	return <div className='gimme-my-space-man'>
		<h2 className='curly yuuge'>Sammy</h2>
		<ul>
			<li>Jiminy Crickets, this guy is huge!</li>
			<li>Turned 1 in February and losing baby fat.</li>
			<li>Looks up to his big brother!</li>
			<li>Loves his dog.</li>
		</ul>
		<Box className='center-box' sx={{ width: totalWidth }}>
			<ImageList variant="masonry" cols={matches ? 2 : 1} gap={12}>
				{itemData.map((item) => (
					<ImageListItem key={item.img}>
						<img
							className='single-img'
							src={`${item.img}?w=248&fit=crop&auto=format`}
							srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
							alt={item.title}
							loading="lazy" />
					</ImageListItem>
				))}
			</ImageList>
		</Box>
	</div>

	
}

export default Sammy